import React from "react"
const InfoContact = ({ data }) => {
  return (
    <div className="evrnt-logation-contact">
      <h3 className="subtitle-contact">staff</h3>
      {data &&
        data.map((item, index) => {
          return (
            <div key={`${index}-contact-info`}>
              <p className="name">
                <a
                  href={`mailto:${item.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.name}
                </a>
              </p>
              <p className="title">{item.profile}</p>
              <p className="extra">{item.description}</p>
              <p className="extra">{item.number}</p>
            </div>
          )
        })}
    </div>
  )
}

export default InfoContact

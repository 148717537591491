import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import InfoContact from "../components/infocontact"
import GoogleMap from "../components/maps"

const ContactPage = ({ data }) => {
  const page = data.wagtail.page

  return (
    <Layout parent={"contact"} siteTitle={"Contact"}>
      <section className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-area">
        <div className="tab-content-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="home-link contact">
                      <h1>{page.title}</h1>
                      <div
                        className="c-title-content"
                        dangerouslySetInnerHTML={{
                          __html: data.wagtail.page.bodySafe,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-7 col-xs-12">
                    <InfoContact data={page.contactInfo} />
                  </div>
                  <div className="col-lg-6 col-md-5 col-xs-12">
                    <div className="map">
                      <a
                        href={`https://maps.google.com?near=${page.formattedAddress}`}
                        target="_blank"
                      >
                        <img
                          src={`https://admin.swimontario.com/${page.imageMaps}`}
                        />
                      </a>
                    </div>

                    <div className="evrnt-logation-contact evrnt-map">
                      <div>
                        <p className="title">{page.namePrincipal}</p>
                        <p className="extra">{page.directionPrincipal}</p>
                        <p className="extra">{page.numberPrincipal}</p>
                        <p className="name">
                          <a
                            href={`mailto:${page.emailPrincipal}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {page.emailPrincipal}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    wagtail {
      page(slug: "contact") {
        ... on ContactPage {
          id
          bodySafe
          button {
            name
            text
            url
          }
          date
          contactInfo {
            ... on ContactInfoBlock {
              id
              name
              number
              profile
              description
              email
            }
          }
          emailPrincipal
          directionPrincipal
          formattedAddress
          imageMaps
          namePrincipal
          numberPrincipal
          title
        }
      }
    }
  }
`
export default ContactPage
